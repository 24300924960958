








































































#main-container{
  height:100%;
  width:100%;
  overflow:hidden !important;
}

header{
  display:none !important;
}

.hola-container{
  h2{
    text-align:center;
    font-size: 2em;
    font-weight: 300;
    line-height: 54px;
    margin: 0 0 32px;
    color: #444242;
  }
  margin-top: 40px;
}
.no-photo{
  text-align: center;
}
@media (min-width: 600px){
  .center{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;  
  }
}

@media (max-width: 600px){
  .center {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 60px;
    width: 80%;
  }
}


.download-button{
  text-align: center;
  background-color: #00add8 !important;
  color:white;
  font-size: 15px;
  border-radius: 20px;
  padding: 12px;
  border:none;
  cursor:pointer;
}

.download-button-container{
  text-align: center;
  margin-top: 40px;
}

.container-no-photo{
  color:#00add8;
  font-size: 1.5em;
}

.container-questions{
  font-size: 1.1em;
  color:var(--color-text-primary);
}

.blue{
  color:#00add8;  
}

